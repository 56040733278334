import React from "react"
import first from "lodash/first"
import orderBy from "lodash/orderBy"
import { Button } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import BookDisplay from "./display"
import Experience from "../library/experience"
import { Books_books } from "../../hasura/queries/types/Books"
import { bookIsUnlocked, getMasteredCount } from "../../lib/helpers"
import { bookSelector, BookState } from "../../hasura/slices/book"
import { createAssignmentClassroomIdAction } from "../../hasura/slices/assignment"
import { userSelector, UserState } from "../../hasura/slices/user"

// @ts-ignore
import masteredIcon from "../../lib/images/trophy.svg"

interface Props {
  isPlayNext?: boolean
  justPlayed?: string
  isTeacherHome?: boolean
}

export default function BookList(props: Props) {
  const dispatch = useDispatch()

  const { books }: BookState = useSelector(bookSelector)
  const { role, hasPremium, user, isStudent, isTeacher }: UserState = useSelector(userSelector)

  const filteredBooks = books.filter((b) => b.live && (isTeacher || b.is_basic || hasPremium))

  const sortedBooks = orderBy(
    filteredBooks.map((book: Books_books) => {
      const experience = first(book.experiences)
      const isUnlocked = user && role && bookIsUnlocked(book, user, role, hasPremium)
      return { book, experience, isUnlocked }
    }),
    [(d) => (d.isUnlocked ? 1 : 0), (d) => d.book.additional_data["unlock_level"]],
    ["desc", "asc"]
  )

  const bookList = (
    <div className="d-flex flex-wrap gap-15px pb-5">
      {sortedBooks
        .filter((d) => !props.isPlayNext || (d.isUnlocked && d.book.title !== props.justPlayed))
        .slice(0, props.isTeacherHome ? 10 : undefined)
        .map((data, idx: number) => {
          const { book, experience, isUnlocked } = data
          const masteredCount = getMasteredCount(experience)

          return (
            <div key={idx} className="width-fit-content">
              <BookDisplay book={book} isUnlocked={isUnlocked} />

              {experience && isStudent && !props.isPlayNext && (
                <Experience correct={experience.correct} seen={experience.seen} mastered={masteredCount > 0} masteredCount={masteredCount} />
              )}

              {!isUnlocked && isStudent && (
                <div className="flex-center mt-1">
                  <p className="text--purple bold mb-0">Unlock at level {book.additional_data["unlock_level"]}</p>
                </div>
              )}

              {isTeacher && isUnlocked && !props.isPlayNext && (
                <div className="text-xs text--gray7 text-right d-flex flex-column align-items-end mt-2 gap-5px">
                  <Button
                    onClick={() => dispatch(createAssignmentClassroomIdAction("new", book.id))}
                    className="width-100px"
                    outline
                    color="primary"
                    size="sm"
                  >
                    Assign
                  </Button>

                  {book.pdf_aws_s3_url && (
                    <Button onClick={() => window.open(book.pdf_aws_s3_url!, "_blank")} className="width-100px" outline color="primary" size="sm">
                      Download
                    </Button>
                  )}
                </div>
              )}

              {isTeacher && !isUnlocked && (
                <div onClick={() => navigate("/premium")} className="text-center mt-2">
                  <p className="text--purple semibold m-0 text-s pointer">Unlock with premium</p>
                </div>
              )}
            </div>
          )
        })}
    </div>
  )

  if (!books.length) {
    return null
  }

  if (props.isPlayNext) {
    return bookList
  }

  return (
    <div className="border-radius-10px mt-4">
      <div className="d-flex align-items-end justify-content-between mb-3">
        <div className="flex-center">
          <h1 className="text-xl bold mb-0 mr-4">
            {isStudent ? "My " : ""}Books
            {props.isTeacherHome ? " (10+)" : ""}
          </h1>

          {props.isTeacherHome && (
            <Button onClick={() => navigate("/books")} className="min-width-100px" outline color="primary">
              View all
            </Button>
          )}
        </div>
      </div>

      {bookList}
    </div>
  )
}
