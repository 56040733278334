import React from "react"

import config from "../../config"
import { BookQuestion } from "../../hasura/slices/book"
import { Choice, Prompt } from "../../hasura/slices/sequence"
import { capitalizeFirstLetter, caseInsEq } from "../../lib/helpers"
import { isConceptQuestion, QuestionLog } from "./helpers"

// @ts-ignore
import questionIcon from "../../lib/images/question-orange.svg"

interface Props {
  handleGuess: (c: Choice, question: BookQuestion, isHint: boolean) => void
  hintsRemaining: number
  isPdf: boolean
  mustAnswerQuestion: boolean
  question: BookQuestion
  questionLog: QuestionLog[]
}

export default function BookQuestionComponent(props: Props) {
  const choices = props.question.content.choices as Choice[]

  const guessed = props.questionLog.filter((q) => q.questionId === props.question.id)
  const isSolved = guessed.some((g) => g.correct)
  const isCustom = caseInsEq(props.question.type, "custom")

  const type = isConceptQuestion(props.question)
    ? `Define ${capitalizeFirstLetter(props.question.concept?.display_name || "")}`
    : props.question.type.replace("Custom", "Knowledge")

  return (
    <div className="bg--primary2 rounded px-3 pt-3 pb-2 mt-3 mb-4 position-relative z-index-800">
      <h6 className="text-s text--white bold">{type}</h6>

      {isCustom && <p className="text-m text--white mb-2">{props.question.content.prompt.map((p: Prompt) => p.value).join(" ")}</p>}

      <div className="gap-10px" style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
        {choices.map((c, idx) => {
          const isGuessed = guessed.some((g) => g.value === c.value)

          return (
            <div
              key={idx}
              onClick={() => props.handleGuess(c, props.question, false)}
              className={`
                rounded p-2  transition-m position-relative flex-center text-center
                ${!props.isPdf ? "shadow-sm" : ""}
                ${isGuessed ? (c.correct ? "bg--success text--white" : "bg--danger text--white") : "bg--white"}
                ${isGuessed || isSolved ? "pe-none" : "pointer"}
              `}
            >
              <p className="text-m m-0">{c.value}</p>

              {config.IS_DEVELOPMENT && c.correct && !props.isPdf && (
                <span className="position-absolute b-0 r-0 m-0 text-xxxs text--success bold pr-1">C</span>
              )}
            </div>
          )
        })}
      </div>

      <div style={{ minHeight: "10px" }} className="d-flex justify-content-end">
        {!props.isPdf && (
          <img
            onClick={() => {
              const correct = choices.find((c) => c.correct)
              if (correct) props.handleGuess(correct, props.question, true)
            }}
            className={`mt-2 p-2 bg--white icon-m rounded rounded-circle shadow-sm ${
              props.hintsRemaining > 0 && !isSolved ? "pointer" : "pe-none opacity-50"
            }`}
            src={questionIcon}
          />
        )}
      </div>
    </div>
  )
}
