import React from "react"
import orderBy from "lodash/orderBy"
import { Button } from "reactstrap"
import { useSelector } from "react-redux"

import BookDisplay from "./display"
import { Books_books } from "../../hasura/queries/types/Books"
import { Concepts_concepts } from "../../hasura/queries/types/Concepts"
import { UserState, userSelector } from "../../hasura/slices/user"

// @ts-ignore
import checkmarkIcon from "../../lib/images/checkmark.svg"
// @ts-ignore
import bookIcon from "../../lib/images/book.svg"
// @ts-ignore
import questionIcon from "../../lib/images/question.svg"

interface Props {
  book: Books_books
  concepts: Concepts_concepts[]
  assign: () => void
  isPdf: boolean
}

const MAX_CONCEPTS = 15

export default function FrontCover(props: Props) {
  const { isTeacher }: UserState = useSelector(userSelector)

  return (
    <div className="d-flex align-items-center vh-100">
      <div className="flex-even bg--gray2 h-100 overflow-scroll">
        <div className="p-3 m-4 bg--white border-radius-10px">
          <h2 className="text-xl garamond m-0">Instructions</h2>

          <div className="my-2 d-flex flex-column gap-15px">
            <div className="d-flex align-items-center">
              <div className="rounded rounded-circle bg--primary mr-2">
                <img className="icon-m p-2" src={bookIcon} />
              </div>

              <p className="text-m m-0">Read each passage carefully</p>
            </div>

            <div className="d-flex align-items-center">
              <div className="rounded rounded-circle bg--primary mr-2">
                <img className="icon-m p-2" src={checkmarkIcon} />
              </div>

              <p className="text-m m-0">Answer questions correctly</p>
            </div>

            {!props.isPdf && (
              <div className="d-flex align-items-center">
                <div className="rounded rounded-circle bg--primary mr-2">
                  <img className="icon-m p-2" src={questionIcon} />
                </div>

                <p className="text-m m-0">Click on underlined words to reveal their definitions</p>
              </div>
            )}
          </div>

          {isTeacher && (
            <div className="mt-3 justify-content-end">
              <Button onClick={props.assign} className="min-width-150px" color="success" size="lg">
                Assign
              </Button>
            </div>
          )}
        </div>

        <div className="p-3 m-4 bg--white border-radius-10px">
          <h2 className="text-xl garamond">Vocabulary</h2>

          <div className="text-m d-flex flex-column gap-5px">
            {orderBy(props.concepts, "display_name")
              .slice(0, MAX_CONCEPTS)
              .map((concept, idx) => {
                return (
                  <div className="d-flex mb-0" key={idx}>
                    <p style={{ minWidth: "125px" }} className="semibold mb-0 mr-1">
                      {concept.display_name}
                    </p>

                    <p className="mb-0">{concept.definition}</p>
                  </div>
                )
              })}
            {props.concepts.length > MAX_CONCEPTS && <p className="text-m m-0">and more...</p>}
          </div>
        </div>
      </div>

      <div />

      <div className="vh-100 d-flex align-items-center justify-content-center flex-column flex-even">
        <div className=" d-flex align-items-end">
          <p className="bold text-l text--secondary">WORDCRAFT</p>
        </div>

        <BookDisplay large isUnlocked {...props} />
      </div>
    </div>
  )
}
