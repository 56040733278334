import React, { useEffect, useRef, useState } from "react"
import chunk from "lodash/chunk"
import clone from "lodash/clone"
import cloneDeep from "lodash/cloneDeep"
import compact from "lodash/compact"
import extend from "lodash/extend"
import flatten from "lodash/flatten"
import moment from "moment"
import orderBy from "lodash/orderBy"
import sample from "lodash/sample"
import sampleSize from "lodash/sampleSize"
import shuffle from "lodash/shuffle"
import sum from "lodash/sum"
import uniq from "lodash/uniq"
import uniqBy from "lodash/uniqBy"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import BackCover from "../components/book/backCover"
import BookNavigation from "../components/book/navigation"
import BookPage from "../components/book/page"
import CONFIG from "../config"
import FrontCover from "../components/book/frontCover"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BOOK_QUESTION_COUNT, imageForPassage, isCorrect, isCorrectWithHint, QuestionLog } from "../components/book/helpers"
import { Books_books } from "../hasura/queries/types/Books"
import { Choice, QuestionContent } from "../hasura/slices/sequence"
import { PassageState, passageSelector, fetchPassagesForConceptAction, unsetPassagesForConceptAction, Annotated } from "../hasura/slices/passage"
import { PassagesForIds_passages } from "../hasura/queries/types/PassagesForIds"
import { QuestionsForPassages_questions } from "../hasura/queries/types/QuestionsForPassages"
import { conceptSelector, ConceptState, fetchConceptsAction } from "../hasura/slices/concept"
import { createAssignmentClassroomIdAction } from "../hasura/slices/assignment"
import { experience_insert_input } from "../../types/globalTypes"
import { fetchUserAction, insertUserEventAction, updateStarCountAction, userSelector, UserState } from "../hasura/slices/user"
import { UserEvent } from "../lib/userEventTypes"

import {
  conceptsForAnnotated,
  pluralize,
  preloadImages,
  searchQueryParams,
  sharesRootWith,
  sleep,
  bookIsUnlocked,
  sameRoots,
  parseIdFrom,
  OLOG,
} from "../lib/helpers"

import {
  BookState,
  bookSelector,
  fetchBooksAction,
  fetchQuestionsForBookAction,
  insertBookExperienceAction,
  setQuestionsForBookFilteredAction,
  updateBookExperienceAction,
} from "../hasura/slices/book"

// @ts-ignore
import arrowIcon from "../lib/images/arrow-thin.svg"

const DEBUG_PASSAGE_ID = null //716204
const HINTS_PER_BOOK = 3
const MAX_PASSAGE_COUNT = 50
const PASSAGES_PER_PAGE = 2

export default function BookComponent() {
  const dispatch = useDispatch()

  const { books, questionsForBook, questionsForBookFiltered }: BookState = useSelector(bookSelector)
  const { accessToken, user, isStudent, isAdmin, isTeacher, role, hasPremium }: UserState = useSelector(userSelector)
  const { passagesForConcept }: PassageState = useSelector(passageSelector)
  const { concepts }: ConceptState = useSelector(conceptSelector)

  const concept = searchQueryParams("s") || "carnivore"
  const isPdf = searchQueryParams("pdf") === "t"
  const book = (books || []).find((b: Books_books) => b.seed === concept)
  const isUnlocked = isPdf || (book && user && role && bookIsUnlocked(book, user, role, hasPremium))

  const [answeredPassageIds, setAnsweredPassageIds] = useState<number[]>([])
  const [correctAndIncorrect, setCorrectAndIncorrect] = useState([0, 0])
  const [displayPdfAnswerKey, setDisplayPdfAnswerKey] = useState(false)
  const [forwardEnabled, setForwardEnabled] = useState(true)
  const [hintsRemaining, setHintsRemaining] = useState(HINTS_PER_BOOK)
  const [isComplete, setIsComplete] = useState(false)
  const [page, setPage] = useState(0)
  const [pageOpacity, setPageOpacity] = useState(1)
  const [passagesForBook, setPassagesForBook] = useState<PassagesForIds_passages[]>([])
  const [questionIds, setQuestionIds] = useState<number[]>([])
  const [questionLog, setQuestionLog] = useState<QuestionLog[]>([])
  const [questionPassageIds, setQuestionPassageIds] = useState<number[]>([])

  const hintsUsed = HINTS_PER_BOOK - hintsRemaining

  const correctAndIncorrectRef = useRef(correctAndIncorrect)
  correctAndIncorrectRef.current = correctAndIncorrect

  const usedImageIds: number[] = []

  /* 
    Effects
  */

  useEffect(() => {
    setPage(-1)
    setForwardEnabled(true)
    setQuestionLog([])
    setIsComplete(false)
    setCorrectAndIncorrect([0, 0])
    setQuestionPassageIds([])
    setAnsweredPassageIds([])
    setQuestionIds([])
    setHintsRemaining(HINTS_PER_BOOK)
  }, [concept])

  useEffect(() => {
    if (!passagesForConcept?.length) return

    const userLevel = user?.level || 0
    const serveAllDifficulties = userLevel > 50 || isAdmin || CONFIG.IS_DEVELOPMENT
    OLOG(`serve all difficulties: ${serveAllDifficulties}`)
    const maxDifficulty = serveAllDifficulties ? 10 : userLevel > 10 ? 5 : 3
    OLOG(`${passagesForConcept.length} passages for book`)

    let passagesForBook = compact(
      passagesForConcept
        .filter((p) => (p.difficulty || 10) <= maxDifficulty)
        .map((p) => {
          const cloned = clone(p)

          const illustratedSorted = orderBy(p.illustrated_passages, (i) => i.image.quality || 0, "desc").filter((i) => {
            const isUsed = usedImageIds.includes(i.image.id)
            if (isUsed) OLOG(`image ${i.id} is already used`)
            return !isUsed
          })

          const illustrated = sample(illustratedSorted.slice(0, 3))
          if (!illustrated) {
            OLOG(`no illustrated for ${p.id}`)
            return
          }

          cloned.illustrated_passages = [illustrated]
          usedImageIds.push(illustrated.image.id)
          return cloned
        })
    )

    OLOG(`${passagesForBook.length} filtered passages for book`)

    passagesForBook =
      CONFIG.IS_DEVELOPMENT && DEBUG_PASSAGE_ID
        ? passagesForBook.filter((p) => p?.id === DEBUG_PASSAGE_ID)
        : sampleSize(shuffle(passagesForBook), MAX_PASSAGE_COUNT)
    setPassagesForBook(passagesForBook)
  }, [passagesForConcept])

  useEffect(() => {
    if (!questionsForBook) return

    // .filter((q) => q.type === "Custom")
    const passageQuestions = uniqBy(shuffle(questionsForBook.passage_questions), (q) => (q.content as QuestionContent).answer[0].value)

    const conceptQuestions = shuffle(
      compact(
        questionsForBook.concept_questions.map((c) => {
          const cloned = cloneDeep(c)
          const passages = passagesForBook.filter((p) =>
            conceptsForAnnotated(p.annotated, concepts).some((c2) => cloned.concept?.display_name === c2.display_name)
          )
          const passage_id = sample(passages)?.id
          if (!passage_id) return null

          const concept = concepts?.find((c2) => c.concept?.display_name === c2.display_name)
          const sharesRootRedHerrings = sampleSize(
            concepts?.filter((c2) => sharesRootWith(concept!, c2) && !sameRoots(concept!, c2) && c2.definition && c2.definition.length < 50),
            3
          ).map((c) => ({
            value: c.definition,
            correct: false,
          }))
          if (!sharesRootRedHerrings.length) return null

          cloned.content.choices = shuffle(sharesRootRedHerrings.concat(...cloned.content.choices))
          cloned.passage_id = passage_id
          return cloned
        })
      )
    )
    const min = Math.max(15, Math.min(passageQuestions.length, conceptQuestions.length))
    console.log(`${passageQuestions.length} passage questions`)
    console.log(`${conceptQuestions.length} concept questions`)
    const questions = uniqBy(shuffle(passageQuestions.slice(0, min).concat(...conceptQuestions.slice(0, min))), "passage_id").slice(
      0,
      BOOK_QUESTION_COUNT
    )
    dispatch(setQuestionsForBookFilteredAction(questions))
    const questionIds = questions.map((q) => q.id)
    setQuestionIds(questionIds)
    const passageIds = questions.map((q) => q.passage_id!)
    setQuestionPassageIds(passageIds)
    preloadImages(compact((passagesForBook || []).map(imageForPassage)).map((i) => i.image.s3_path))
  }, [questionsForBook, passagesForBook])

  useEffect(() => {
    if (passagesForConcept?.length) return

    dispatch(fetchPassagesForConceptAction(concept))
    dispatch(fetchConceptsAction(""))
    return () => {
      dispatch(unsetPassagesForConceptAction())
    }
  }, [concept])

  useEffect(() => {
    if (!accessToken) return

    const event = { user_id: parseIdFrom(accessToken), user_event_type_id: UserEvent.OpenedBook }
    dispatch(insertUserEventAction(accessToken, event))
  }, [accessToken])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!CONFIG.IS_DEVELOPMENT && !isAdmin) return

    window.addEventListener("keydown", skipQuestion)

    return () => {
      window.removeEventListener("keydown", skipQuestion)
    }
  }, [page, isAdmin])

  const lemmas = uniq(flatten(passagesForBook.map((p) => (p.annotated as Annotated).tokens.map((t) => t.lemma))))
  const passageIds = (passagesForBook || []).map((p) => p.id)
  const filteredConcepts = (concepts || []).filter(
    (c) => c.definition && c.definition.length < 200 && c.root_appearances.length && lemmas.includes(c.display_name)
  )

  useEffect(() => {
    if (!passagesForBook?.length || !concepts?.length) return

    const conceptIds = filteredConcepts.map((b) => b.id)
    dispatch(fetchBooksAction(""))
    dispatch(fetchQuestionsForBookAction("", passageIds, conceptIds))
  }, [passagesForBook, concepts])

  /* 
    Methods
  */

  const isFrontCover = page === -1
  const chunked = chunk(passagesForBook, PASSAGES_PER_PAGE)
  const filtered = isFrontCover ? [] : chunked[page]
  const mustAnswerQuestion = (filtered || []).some(
    (f) => questionPassageIds.includes(f.id) && !questionLog.find((q) => q.passageId === f.id && q.correct)
  )

  const completedBook = async () => {
    setIsComplete(true)

    if (isStudent) {
      const [correct, incorrect] = correctAndIncorrectRef.current
      const seen = correct + incorrect
      const bookExperience = user?.experience.find((e) => e.book_id === book?.id)
      const run = { correct, seen, completed_at: moment(), hintsUsed }

      if (bookExperience) {
        const runs = bookExperience.additional_data["runs"].concat(run)
        const additional_data = extend(clone(bookExperience.additional_data), { runs })
        await dispatch(
          updateBookExperienceAction(accessToken!, bookExperience.id, bookExperience.correct + correct, bookExperience.seen + seen, additional_data)
        )
      } else {
        const experience: experience_insert_input = {
          additional_data: { runs: [run] },
          book_id: book!.id,
          correct,
          seen,
          user_id: user!.id,
        }
        await dispatch(insertBookExperienceAction(accessToken!, experience))
      }
      await dispatch(updateStarCountAction(accessToken!, user!.id))
      dispatch(fetchUserAction(accessToken!, user!.id))
    }

    if (user && accessToken) {
      const event = { user_id: user.id, user_event_type_id: UserEvent.FinishedBook }
      dispatch(insertUserEventAction(accessToken, event))
    }

    dispatch(fetchBooksAction(accessToken!))
  }

  const skipQuestion = (e: any) => {
    if (e.key === "ArrowRight") goForward(true)
    if (e.key === "ArrowLeft") goBack()
  }

  const handleClickedHint = () => {
    setHintsRemaining(hintsRemaining - 1)
    if (user && accessToken) {
      const event = { user_id: user.id, user_event_type_id: UserEvent.ClickedHint }
      dispatch(insertUserEventAction(accessToken, event))
    }
  }

  const handleGuess = (c: Choice, question: QuestionsForPassages_questions, isHint: boolean) => {
    // @ts-ignore
    const updated = questionLog.concat({ correct: c.correct, value: c.value, questionId: question.id, passageId: question.passage_id, isHint })
    setQuestionLog(updated)
    const answeredPassageIds = uniq(updated.map((q) => q.passageId))
    const correct = answeredPassageIds.filter((id) => isCorrect(id, questionLog) && !isCorrectWithHint(id, questionLog)).length
    const incorrect = answeredPassageIds.length - correct - hintsUsed
    setAnsweredPassageIds(answeredPassageIds)
    setCorrectAndIncorrect([correct, incorrect])
    if (isHint) handleClickedHint()
  }

  const goBack = () => {
    if (pageOpacity === 0) return

    updatePage(page - 1)
  }

  const goForward = (force?: boolean) => {
    if ((!forwardEnabled && !force) || !isUnlocked) return

    if (page === -1 || chunked[page + 1]) {
      updatePage(page + 1)
    } else {
      completedBook()
    }
  }

  const updatePage = async (page: number) => {
    const fadeInSeconds = CONFIG.IS_DEVELOPMENT || isAdmin || isTeacher ? 0.5 : 0.75
    const nextDisabledSeconds = CONFIG.IS_DEVELOPMENT || isAdmin || isTeacher ? 0 : 3.5
    setPageOpacity(0)
    setForwardEnabled(false)
    await sleep(fadeInSeconds)
    setPageOpacity(1)
    setPage(page)
    await sleep(nextDisabledSeconds)
    setForwardEnabled(true)
  }

  const isLastPage = Boolean(!chunked[page + 1])

  const answerKey = passagesForBook
    .map((p) => {
      const question = (questionsForBookFiltered || []).find((q) => q.passage_id === p.id && questionIds.includes(q.id))
      if (!question) return null

      return (question.content as QuestionContent).answer[0].value
    })
    .filter((a) => a)

  if (displayPdfAnswerKey) {
    return (
      <Layout noHeader noStyles>
        <div style={{ padding: "100px 20px" }} className="vh-100 border-left border-right max-width-1200px position-relative w-100 mx-auto">
          <h2 className="text-xxl garamond mb-2">Answer Key</h2>
          {answerKey.map((a, idx) => (
            <p className="text-m mb-1" key={idx}>
              {idx + 1}) {a}
            </p>
          ))}
        </div>
      </Layout>
    )
  }

  return (
    <Layout noHeader noStyles>
      <SEO title="Book" />

      {isPdf ? (
        <Button
          style={{ visibility: "hidden", position: "absolute" }}
          id={isLastPage ? "complete" : "next"}
          onClick={() => (isLastPage ? setDisplayPdfAnswerKey(true) : updatePage(page + 1))}
        />
      ) : null}

      {passagesForBook.length ? (
        <div>
          <BookNavigation
            answeredPassageIds={answeredPassageIds}
            description={`${pluralize("hint", hintsRemaining)} remaining`}
            goBack={goBack}
            goForward={goForward}
            isBackCover={isComplete}
            isFrontCover={isFrontCover}
            isPdf={isPdf}
            mustAnswerQuestion={mustAnswerQuestion}
            page={page}
            pageCount={passagesForBook.length}
            questionCount={questionIds.length}
            questionLog={questionLog}
            setPage={setPage}
            title={book?.title}
          />

          <div className="border-left border-right max-width-1200px position-relative w-100 mx-auto position-relative">
            <div style={{ opacity: pageOpacity }} className="transition-xs mx-auto min-vh-100 d-flex flex-column">
              {isComplete ? (
                <BackCover correctCount={correctAndIncorrect[0]} questionCount={sum(correctAndIncorrect)} book={book!} hintsUsed={hintsUsed} />
              ) : isFrontCover ? (
                <FrontCover
                  isPdf={isPdf}
                  assign={() => dispatch(createAssignmentClassroomIdAction("new", book?.id))}
                  concepts={filteredConcepts}
                  book={book!}
                />
              ) : (
                <BookPage
                  handleClickedHint={handleClickedHint}
                  handleGuess={handleGuess}
                  hintsRemaining={hintsRemaining}
                  mainOpacity={pageOpacity}
                  mustAnswerQuestion={mustAnswerQuestion}
                  passages={filtered}
                  questionIds={questionIds}
                  questionLog={questionLog}
                  isPdf={isPdf}
                />
              )}
            </div>

            {isFrontCover && !isComplete && !isPdf && (
              <div className="position-absolute t-0 b-0 r-0 d-flex d-flex align-items-end justify-content-center flex-column h-100 pr-2">
                {isUnlocked ? <p className="text-m font-italic m-0">Start book</p> : <p className="text-m bold text--purple m-0">Book Locked</p>}
                {isUnlocked && (
                  <div className="position-relative mr-4">
                    <img style={{ marginTop: "-15px" }} className="position-absolute l-0 icon-l move-left-right" src={arrowIcon} />
                  </div>
                )}
              </div>
            )}
          </div>

          {!isFrontCover && !isComplete && (
            <div
              style={{ opacity: pageOpacity }}
              className="position-absolute w-100 max-width-1200px mx-auto l-0 r-0 t-0 mt-4 z-index-900 text-xl text--gray7 garamond transition-s"
            >
              <div className="flex-center position-absolute t-0 l-0 ml-4">
                <p className="m-0">{page * 2 + 1}</p>
              </div>

              <div className="flex-center position-absolute t-0 r-0 mr-4">
                <p className="m-0">{page * 2 + 2}</p>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </Layout>
  )
}
