import React, { useEffect, useState } from "react"
import range from "lodash/range"
import { Button, Progress } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import CONFIG from "../../config"
import DangerModal from "../common/modals/danger"
import { NotificationId, setNotificationAction } from "../../hasura/slices/notification"
import { UserState, userSelector } from "../../hasura/slices/user"
import { isCorrect, isCorrectWithHint, QuestionLog } from "./helpers"

// @ts-ignore
import exitIcon from "../../lib/images/exit.svg"
// @ts-ignore
import exitWhiteIcon from "../../lib/images/exit-white.svg"
// @ts-ignore
import starIcon from "../../lib/images/star.svg"
// @ts-ignore
import starRedIcon from "../../lib/images/star-red.svg"
// @ts-ignore
import starGrayIcon from "../../lib/images/star-gray.svg"
// @ts-ignore
import arrowWhiteIcon from "../../lib/images/arrow-white.svg"
// @ts-ignore
import starOrangeIcon from "../../lib/images/star-orange.svg"

interface Props {
  answeredPassageIds: number[]
  goBack: () => void
  description: string
  goForward: () => void
  isFrontCover: boolean
  isBackCover: boolean
  mustAnswerQuestion: boolean
  questionCount: number
  title?: string
  page: number
  pageCount: number
  questionLog: QuestionLog[]
  setPage: (page: number) => void
  isPdf: boolean
}

export default function BookNavigation(props: Props) {
  const dispatch = useDispatch()

  const [fullNavOpen, setFullNavOpen] = useState(false)
  const [displayConfirmExitModal, setDisplayConfirmExitModal] = useState(false)

  const { isStudent }: UserState = useSelector(userSelector)

  const { mustAnswerQuestion } = props

  useEffect(() => {
    setFullNavOpen(false)
  }, [props.page])

  const isCover = props.isFrontCover || props.isBackCover

  const clickedExit = () => (!isCover && props.answeredPassageIds.length > 2 ? setDisplayConfirmExitModal(true) : navigate("/home"))

  if (props.isPdf) return null

  return (
    <div>
      <DangerModal
        setIsOpen={setDisplayConfirmExitModal}
        isOpen={displayConfirmExitModal}
        confirm={() => navigate("/home")}
        title="Exit before finishing?"
        body="Are you sure you want to exit now? WARNING: you will lose your progress on this book."
        confirmText="Exit"
      />

      {!isCover && (
        <div className="position-fixed t-0 r-0 l-0 z-index-1000000 transition-m">
          <div
            className="bg--primary px-3 transition-m flex-center"
            style={{ height: fullNavOpen ? "60px" : 0, visibility: fullNavOpen ? "visible" : "hidden" }}
          >
            <div className="flex-even">
              <Button onClick={() => props.setPage(-1)} color="light">
                Go to start
              </Button>
            </div>

            {fullNavOpen && <h1 className="m-0 bold text--white text-l">{props.title}</h1>}

            <div className="flex-even d-flex justify-content-end">
              <img className="icon-s pointer" onClick={clickedExit} src={exitWhiteIcon} />
            </div>
          </div>

          <div
            onClick={() => setFullNavOpen(!fullNavOpen)}
            className="bg--primary mx-auto flex-center pointer"
            style={{ opacity: fullNavOpen ? 1 : 0.5, borderRadius: "0 0 20px 20px", height: "30px", width: "100px" }}
          >
            <img
              style={{ transform: fullNavOpen ? "rotate(180deg)" : "rotate(0deg)", height: "30px", width: "auto" }}
              className="transition-m"
              src={arrowWhiteIcon}
            />
          </div>
        </div>
      )}

      <img
        style={{ opacity: 0.5 }}
        className="position-fixed t-0 r-0 mt-1 mr-1 icon-xl pointer z-index-10000000"
        onClick={clickedExit}
        src={exitIcon}
      />

      {!isCover && (
        <div style={{ marginTop: "40px" }} className="position-fixed width-fit-content flex-center flex-column t-0 l-0 r-0 mx-auto z-index-1000">
          <div style={{ gap: "5px" }} className="d-flex">
            {range(props.questionCount).map((n) => {
              const id = props.answeredPassageIds[n]
              const answered = id !== undefined
              const correct = isCorrect(id, props.questionLog)
              const correctWithHint = isCorrectWithHint(id, props.questionLog)

              return (
                <img
                  key={n}
                  className="icon-s"
                  src={answered ? (correctWithHint ? starOrangeIcon : correct ? starIcon : starRedIcon) : starGrayIcon}
                />
              )
            })}
          </div>

          <p className="mb-0 mt-1 text-xs text--gray7">{props.description}</p>
        </div>
      )}

      {!isCover && (
        <div
          style={{ width: "calc((100vw - 1200px) / 2) !important" }}
          onClick={props.goBack}
          className="cursor-paginate-back position-fixed l-0 t-0 b-0 min-width-250px h-100 z-index-500"
        ></div>
      )}

      {!props.isBackCover && (
        <div
          onClick={() => {
            if (mustAnswerQuestion) {
              dispatch(setNotificationAction(NotificationId.MustAnswerQuestion))
            } else {
              props.goForward()
            }
          }}
          style={{ width: "calc((100vw - 1200px) / 2) !important" }}
          className="cursor-paginate-forward position-fixed r-0 t-0 b-0 min-width-250px h-100 z-index-500"
        ></div>
      )}

      <div className="position-fixed b-0 r-0 l-0 z-100000 transition-m bg--white">
        <div
          className="border-top transition-m flex-center"
          style={{ height: fullNavOpen ? "70px" : 0, visibility: fullNavOpen ? "visible" : "hidden" }}
        >
          <Progress
            onClick={(e) => {
              props.setPage(Math.floor((e.nativeEvent.offsetX * Math.floor(props.pageCount / 2)) / e.currentTarget.clientWidth))
            }}
            className={`pointer mx-2 max-width-500px w-100 mb-2 ${isStudent && !CONFIG.IS_DEVELOPMENT ? "pe-none" : ""}`}
            color="primary"
            value={((props.page * 2 + 1) * 100) / props.pageCount}
          />
          <p className="m-0 position-absolute mx-auto pt-4 mt-2 bold text-s text--gray8 pe-none w-100 text-center">
            page {Math.max(0, props.page * 2 + 1)} of {props.pageCount}
          </p>
        </div>
      </div>
    </div>
  )
}
